.badge-button {

    padding-right: 2rem;

    /* Custom CSS to make a badge clickable and react to interaction */
    &:hover {
        @include box-shadow($btn-hover-box-shadow);
        transform: translateY(-1px);
        filter: brightness(85%);
    }

    &:not(:last-child) {
        margin-right: .5rem;
    }

    &:focus {
        box-shadow:  0 0px 0px rgba(0, 0, 0, 0);
        transform: translateY(0px);
    }

    &:active {
        box-shadow:  0 0px 0px rgba(0, 0, 0, 0);
        transform: translateY(0px);
    }
}

.badge {
    text-transform: $badge-text-transfom;

    a {
        color: #FFF;
    }
}

.badge-day {
    border-radius: 30px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 4px;
    padding-right: 4px;
    line-height: 1;
    //transform: scale(1, .95);
    font-weight: 600;
    font-size: 75%;
    font-family: 'Inconsolata';
}

// Variations
.badge-pill {
    padding-right: $badge-pill-padding-x;
    padding-left: $badge-pill-padding-x;
}

.badge-circle {
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    font-size: .875rem;
}

// Multiple inline badges
.badge-inline {
    margin-right: .625rem;
}

.badge-inline + span {
    top: 2px;
    position: relative;
}

.badge-inline + span > a {
    text-decoration: underline;
} 

// Sizes
.badge-md {
    padding: .65em 1em;
}

.badge-lg {
    padding: .85em 1.375em;
}


// Color variations

.badge-secondary {
    color: $gray-800;
}

// Link badges

.btn {
    .badge {
        &:not(:first-child) {
            margin-left: .5rem;
        }
        &:not(:last-child) {
            margin-right: .5rem;
        }
    }
}